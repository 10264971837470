import React, { useState } from "react"
import { Link } from "gatsby"

import {
  Button,
  Container,
  Text,
  Div,
  Icon,
  Input,
  Anchor,
  Image,
  Row,
  Col,
} from "atomize"

import mockup from "../../images/3d-flame-288.png"
import { PrimaryColor, SecondaryColor } from "../colors"
import logo from "../../images/logo.svg"
// import Notification from './uicomponents/Notification'
import firebase from "gatsby-plugin-firebase"
import { ModalSize } from "../formModal"
import TextTransition, { presets } from "react-text-transition"
import { nganhs } from "../CardCategory"

function gtag_report_conversion_button(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url
    }
  }
  if (typeof window.gtag != "undefined") {
    window.gtag("event", "conversion", {
      send_to: "AW-333632733/VTQdCJbKyusCEN2pi58B",
      event_callback: callback,
    })
  }
  return false
}

const TEXTS = nganhs.map((r) => r.name)

const HeroSection = ({ layout }) => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 2000)
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <>
      <Div
        tag="section"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { md: "4rem" },
          r: { md: "4rem" },
        }}
        flexDir="row"
      >
        <Row align={"center"}>
          <Col size={{ xs: 12, lg: 8 }}>
            <Container d="flex" flexDir="column" align="left">
              <Text
                tag="h1"
                textWeight="700"
                textAlign="left"
                textSize="display3"
                fontFamily="secondary"
                textColor={"black"}
                m={{ b: "1rem" }}
              >
                Callbot gọi tự động{" "}
                <span style={{ color: PrimaryColor }}>Telemarketing</span> &{" "}
                <span style={{ color: PrimaryColor }}>Telesales </span>
                <span>
                  {" "}
                  Tối Ưu Cho Ngành{" "}
                  <TextTransition
                    inline
                    style={{ color: PrimaryColor }}
                    text={TEXTS[index % TEXTS.length]}
                    springConfig={presets.gentle}
                  />
                </span>
              </Text>
              <Text
                tag="h2"
                textWeight="400"
                maxW="36rem"
                textSize="subheader"
                textAlign="left"
                fontFamily="secondary"
                textColor="medium"
                m={{ b: "2.5rem" }}
              >
                Giải pháp Auto Call tối ưu chi phí giúp doanh nghiệp marketing
                và telesales tự động
              </Text>

              <Div d="flex" left flexDir={{ xs: "column", sm: "row" }}>
                <Button
                  h="3rem"
                  w={{ xs: "100%", sm: "16rem" }}
                  bg="info700"
                  hoverBg="info600"
                  rounded="lg"
                  m={{ r: "1rem", b: { xs: "1rem", sm: "0" } }}
                  onClick={() => {
                    setShowModal(true)
                    firebase.analytics().logEvent("open_contact_form")
                  }}
                >
                  Liên hệ tư vấn
                </Button>
                <Anchor
                  href="https://growth.ucall.vn"
                  target="_blank"
                  onClick={() => {
                    firebase.analytics().logEvent("tap_on_trai_nghiem_mien_phi")
                  }}
                >
                  <Button
                    h="3rem"
                    w={{ xs: "100%", sm: "15rem" }}
                    bg="transparent"
                    hoverBg={PrimaryColor}
                    border="1px solid"
                    borderColor={PrimaryColor}
                    hoverBorderColor={PrimaryColor}
                    rounded="lg"
                    p={{ l: "0.5rem", r: "1rem" }}
                    textColor={PrimaryColor}
                    hoverTextColor={"white"}
                    prefix={
                      <Icon
                        name="Play"
                        size="18px"
                        m={{ r: "0.5rem" }}
                        color="black400"
                      />
                    }
                  >
                    Trải nghiệm miễn phí
                  </Button>
                </Anchor>
              </Div>
            </Container>
          </Col>
          <Col size={{ xs: 12, lg: 4 }}>
            <Image src={mockup} />
          </Col>
        </Row>
      </Div>
      <ModalSize isOpen={showModal} onClose={() => setShowModal(false)} />
    </>
  )
}

export default HeroSection
